import React, { ReactFragment } from 'react'
import useResponsive from '../../hooks/useResponsive'

const ContentDesktop: ReactFragment = (
    <>
        Inceptive is a boutique technology company
        <br />
        from Belgrade, Serbia, founded in 2018.
        <br />
        <br />
        With a deep understanding of technology&#39;s essence, we decompose problems
        <br />
        and produce optimal solutions applicable across different domains.
        <br />
        <br />
        Experienced engineers, mathematicians, designers, and business people
        <br />
        found a home at Inceptive and are furthering our expertise every day.
    </>
)

const ContentMobile: ReactFragment = (
    <>
        Inceptive is a boutique technology company with offices in Amsterdam and Belgrade, founded in 2018.
        <br />
        <br />
        With a deep understanding of technology&#39;s essence, we decompose problems and produce optimal solutions applicable across
        different domains.
        <br />
        <br />
        Experienced engineers, mathematicians, designers, and business people found a home at Inceptive and are furthering our expertise
        every day.
    </>
)

const AboutUsContent = (): ReactFragment => {
    const { isMedium } = useResponsive()

    return isMedium ? ContentMobile : ContentDesktop
}

export default AboutUsContent
