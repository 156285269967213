import React from 'react'
import BackgroundTexture from '../../components/BackgroundTexture/BackgroundTexture'
import AboutUsContent from './AboutUsContent'
import InteractiveCard from '../../components/InteractiveCard/InteractiveCard'
import { ThemeType } from '../../globals/ThemeContext'
import ThemeConsumer from '../../pages/ThemeConsumer'
import './AboutUsPage.scss'

const AboutUsPage = (): JSX.Element => {
    return (
        <ThemeConsumer theme={ThemeType.DARK}>
            <div className="container">
                <BackgroundTexture type="gradient" />
                <div className="about-us-page">
                    <div className="heading">
                        <span>What is</span>
                        <span className="emp-text">Inceptive</span>
                    </div>
                    <div className="content">{AboutUsContent()}</div>

                    <div className="principles">
                        <div className="heading column align-left">
                            <div className="emp-text">Core</div>
                            <div>principles</div>
                        </div>

                        <div className="boxes">
                            <div className="item-1">
                                <InteractiveCard>
                                    <>
                                        <div>Intellectual</div>
                                        <div>honesty</div>
                                    </>
                                </InteractiveCard>
                            </div>
                            <div className="item-2">
                                <InteractiveCard>
                                    <div>Zero-overhead</div>
                                </InteractiveCard>
                            </div>
                            <div className="item-3">
                                <InteractiveCard>
                                    <>
                                        <div>Measure everything</div>
                                        <div>Measure precise</div>
                                    </>
                                </InteractiveCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeConsumer>
    )
}

export default AboutUsPage
