import React, { useState, useContext } from 'react'
import './App.scss'
import { ThemeContext, ThemeType, setDocumentBackground } from './globals/ThemeContext'
import { Router } from '@reach/router'
import type { RouteComponentProps } from '@reach/router'
import HomePage from './pages/HomePage/HomePage'
import CareersPage from './pages/CareersPage/CareersPage'
import JobDetailsPage from './pages/JobDetailsPage/JobDetailsPage'
import ContactPage from './pages/ContactPage/ContactPage'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import AboutUsPage from './pages/AboutUs/AboutUsPage'

const App: React.FC = (): JSX.Element => {
    const [state, setState] = useState({
        theme: useContext(ThemeContext).theme,
        toggle: (theme: ThemeType) => {
            setState({
                ...state,
                theme
            })
            setDocumentBackground(theme)
        }
    })

    const location = window.location.pathname

    return (
        <ThemeContext.Provider value={state}>
            <div className="app">
                <Navbar theme={state.theme} />
                <Router>
                    <RouterPage path="/" pageComponent={<HomePage />} />
                    <RouterPage path="/about-us" pageComponent={<AboutUsPage />} />
                    <RouterPage path="/careers" pageComponent={<CareersPage />} />
                    <JobDetailsPage path="/careers/:jobUrl" />
                    <RouterPage path="/contact/:locationUrl" pageComponent={<ContactPage />} />
                </Router>
                {location.includes('/contact') === false && <Footer />}
            </div>
        </ThemeContext.Provider>
    )
}

export default App

const RouterPage = (props: { pageComponent: JSX.Element } & RouteComponentProps) => props.pageComponent
