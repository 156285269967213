export enum ButtonType {
    ICON = 'icon',
    TEXT = 'text'
}

export enum TextButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GHOST = 'ghost'
}
