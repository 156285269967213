import React, { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'
import GoogleMapReact from 'google-map-react'
import './Map.scss'
import { MapTheme } from '../../globals/MapTheme'
import MapMarker from '../MapMarker/MapMarker'
import { locationUrl as locationUrlEnum } from '../../pages/ContactPage/ContactPageData'

interface IProps {
    location: locationUrlEnum
}

const Map: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { location } = props

    const belgradeDesktopDefaultProps = {
        center: {
            lat: 44.7574001270289,
            lng: 20.441427647509258
        },
        zoom: 16
    }

    const belgradeTabletDefaultProps = {
        center: {
            lat: 44.75733924707285,
            lng: 20.43192193470612
        },
        zoom: 16
    }

    const amsterdamDesktopDefaultProps = {
        center: {
            lat: 52.340282199799645,
            lng: 4.8836809711323665
        },
        zoom: 16
    }

    const amsterdamTabletDefaultProps = {
        center: {
            lat: 52.34024325531374,
            lng: 4.873981629358068
        },
        zoom: 16
    }

    const [isDesktop] = useState(window.matchMedia('(min-width: 960px)').matches)

    const map = match<{ location: locationUrlEnum; isDesktop: boolean }, ReactNode>({ location, isDesktop })
        .with({ location: locationUrlEnum.BELGRADE, isDesktop: true }, () => (
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCVjfjNkkN0JT8yjWE1HmWkGONKqSYNPu0' }}
                options={{ styles: MapTheme }}
                center={belgradeDesktopDefaultProps.center}
                zoom={belgradeDesktopDefaultProps.zoom}
            >
                <MapMarker lat={44.75733924707285} lng={20.43192193470612} />
            </GoogleMapReact>
        ))
        .with({ location: locationUrlEnum.BELGRADE, isDesktop: false }, () => (
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCVjfjNkkN0JT8yjWE1HmWkGONKqSYNPu0' }}
                options={{ styles: MapTheme }}
                center={belgradeTabletDefaultProps.center}
                zoom={belgradeTabletDefaultProps.zoom}
            >
                <MapMarker lat={44.75716587735445} lng={20.432873491055915} />
            </GoogleMapReact>
        ))
        .with({ location: locationUrlEnum.AMSTERDAM, isDesktop: true }, () => (
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCVjfjNkkN0JT8yjWE1HmWkGONKqSYNPu0' }}
                options={{ styles: MapTheme }}
                center={amsterdamDesktopDefaultProps.center}
                zoom={amsterdamDesktopDefaultProps.zoom}
            >
                <MapMarker lat={52.34024325531374} lng={4.873981629358068} />
            </GoogleMapReact>
        ))
        .with({ location: locationUrlEnum.AMSTERDAM, isDesktop: false }, () => (
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCVjfjNkkN0JT8yjWE1HmWkGONKqSYNPu0' }}
                options={{ styles: MapTheme }}
                center={amsterdamTabletDefaultProps.center}
                zoom={amsterdamTabletDefaultProps.zoom}
            >
                <MapMarker lat={52.34024325531374} lng={4.873981629358068} />
            </GoogleMapReact>
        ))
        .exhaustive()

    return (
        <div className="map-container">
            <div className="map">{map}</div>
        </div>
    )
}

export default Map
