import { useLayoutEffect, useState } from 'react'

export function useWindowSize(cb: () => void): [number, number] {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
            cb()
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
        // lint disabled for dependency array, hook should trigger on first render only
        // eslint-disable-next-line
    }, [])
    return size as [number, number]
}
