import React from 'react'
import './JobDetailsList.scss'

interface IProps {
    listTitle?: string
    listItems?: React.ReactNode
}

const JobDetailsList: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { listTitle = '', listItems } = props

    return (
        <div className={`list-section`}>
            <p className="list-title">{listTitle}</p>
            <div className={`${listTitle.toLowerCase()} list`}>{listItems}</div>
        </div>
    )
}

export default JobDetailsList
