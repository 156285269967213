import React, { ReactNode } from 'react'
import './ContactCard.scss'

interface IProps {
    title: string
    info: ReactNode
}

const ContactCard: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { title, info } = props

    return (
        <div className="contact-card">
            <div className="card border">
                <span className="title">{title}</span>
                <div className="info">{info}</div>
            </div>
        </div>
    )
}

export default ContactCard
