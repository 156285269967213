import { Link } from '@reach/router'
import React from 'react'
import './MenuItem.scss'

interface IProps {
    pageUrl: string
    label: string
    onClick?: () => void
}

const HamburgerMenuItem: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { pageUrl = '', label = '', onClick } = props

    return (
        <div className="menu-item">
            <Link to={pageUrl} className="links" onClick={onClick}>
                {label}
            </Link>
        </div>
    )
}

export default HamburgerMenuItem
