import React, { useRef, useState } from 'react'
import { ThemeType } from '../../globals/ThemeContext'
import JobList from '../../components/JobList/JobList'
import QuoteCard from '../../components/QuoteCard/QuoteCard'
import BackgroundTexture from '../../components/BackgroundTexture/BackgroundTexture'
import './CareersPage.scss'
import ThemeConsumer from '../ThemeConsumer'
import useResponsive from '../../hooks/useResponsive'
import { useWindowSize } from '../../hooks/useWindowSize'

const heightGetter = (ref: React.RefObject<HTMLDivElement>): number => (ref.current ? ref.current.clientHeight : 0)

const CareersPage = (): JSX.Element => {
    const listRef = useRef<HTMLDivElement>(null)
    const [listHeight, setListHeight] = useState<number>(heightGetter(listRef))

    useWindowSize(() => {
        //this check is important - in most cases dimensions are the same
        if (listRef.current?.clientHeight !== listHeight) {
            setListHeight(heightGetter(listRef))
        }
    })

    const { isSmall, isMedium } = useResponsive()

    //30vh - fixed in css
    const style = `calc(30vh + ${listHeight}px + 240px)`

    return (
        <ThemeConsumer theme={ThemeType.LIGHT}>
            <div className="careers-page" role="main" style={{ height: !isSmall && !isMedium ? style : '' }}>
                <QuoteCard quoteText={'Use our success to fuel yours.'} />
                <JobList ref={listRef} />
                <BackgroundTexture type="black" />
            </div>
        </ThemeConsumer>
    )
}

export default CareersPage
