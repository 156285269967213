import React from 'react'
import ReactDOM from 'react-dom'
import { render } from 'react-dom'
import './index.scss'
import App from './App'

if (process.env.NODE_ENV === 'development') {
    //eslint-disable-next-line @typescript-eslint/no-var-requires
    const axe = require('@axe-core/react')
    axe(React, ReactDOM, 1000)
}

render(<App />, document.getElementById('root'))
