import React from 'react'
import TextureBlack from '../../../public/assets/dots-black.svg'
import TextureGradient from '../../../public/assets/dots-gradient.svg'
import './BackgroundTexture.scss'

type BackgroundType = 'black' | 'gradient' | 'mobile'

interface IProps {
    type: BackgroundType
}

export const BackgroundTexture = ({ type }: IProps): JSX.Element => {
    return (
        <div
            className={`background-texture
            ${type === 'black' ? 'black' : 'gradient'}
            `}
        >
            {type === 'black' ? <TextureBlack /> : <TextureGradient />}
        </div>
    )
}

export default BackgroundTexture
