import React, { ReactFragment } from 'react'
import { match } from 'ts-pattern'

export enum locationUrl {
    BELGRADE = 'belgrade'
}

const belgradeInfo: ReactFragment = (
    <>
        <p>Kneza Vi&scaron;eslava 88</p>
        <p>Avala Film</p>
        <p>11030 Belgrade, Serbia</p>
        <p>+381 11 6904 863</p>
        <p>info@inceptive.io</p>
    </>
)

export const determineContactCardTitle = (location: locationUrl): string =>
    match<locationUrl, string>(location)
        .with(locationUrl.BELGRADE, () => 'Belgrade office')
        .otherwise(() => 'Belgrade office')

export const determineContactCardInfo = (location: locationUrl): ReactFragment =>
    match<locationUrl, ReactFragment>(location)
        .with(locationUrl.BELGRADE, () => belgradeInfo)
        .otherwise(() => belgradeInfo)
