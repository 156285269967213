import React, { useState } from 'react'
import { Link } from '@reach/router'
import './Navbar.scss'
import Logo from '../../../public/assets/logo.svg'
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon'
import NavbarMenu from '../NavbarMenu/NavbarMenu'
import { match, P } from 'ts-pattern'

interface IProps {
    theme: string
}

const Navbar: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { theme } = props
    const [navbarVisibility, setNavbarVisibility] = useState(false)
    const [isHamburgerActive, setHamburgerActive] = useState(false)

    const closeHamburgerMenu = () => {
        setNavbarVisibility(false)
        setHamburgerActive(false)
        window.scrollTo(0, 0)
    }

    const changeNavbarState = () => {
        setNavbarVisibility(!isHamburgerActive)
        setHamburgerActive(!isHamburgerActive)
    }

    const changeNavbarBackgroundOnScroll = () =>
        match<number>(window.scrollY)
            .with(
                P.when<number, (value: number) => boolean>((x: number) => !!(x > 80)),
                () => setNavbarVisibility(true)
            )
            .otherwise(() => setNavbarVisibility(false))

    window.addEventListener('scroll', changeNavbarBackgroundOnScroll)

    return (
        <div>
            <nav className={navbarVisibility ? 'navbar active' : 'navbar'}>
                <div className="container navbar-container">
                    <Link to="/" onClick={closeHamburgerMenu} className={`logo ${theme}-theme`} aria-label="Inceptive logo">
                        <Logo />
                    </Link>

                    <HamburgerIcon isHamburgerActive={isHamburgerActive} onHamburgerClick={changeNavbarState} theme={theme} />

                    <NavbarMenu isHamburgerActive={isHamburgerActive} onMenuItemClick={closeHamburgerMenu} theme={theme} />
                </div>
            </nav>
        </div>
    )
}

export default Navbar
