import Position from 'google-map-react'
import React from 'react'
import './MapMarker.scss'

const MapMarker: React.FC<Position> = (): JSX.Element => {
    return (
        <div className="waves">
            <div className="dot"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    )
}

export default MapMarker
