import React from 'react'
import './QuoteCard.scss'

interface IProps {
    quoteText: string
}

const QuoteCard: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { quoteText } = props

    return (
        <div className="quote-card-border">
            <div className="quote-card-fill">
                <h1 className="quote-text">{quoteText}</h1>
            </div>
        </div>
    )
}

export default QuoteCard
