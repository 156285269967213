import React, { MouseEventHandler } from 'react'
import { match } from 'ts-pattern'
import { TextButtonType as TextButtonTypeEnum, ButtonType as ButtonTypeEnum } from '../../globals/ButtonType'
import './Button.scss'

interface IProps {
    buttonType: ButtonTypeEnum
    textButtonType?: TextButtonTypeEnum
    textButtonLabel?: string
    iconButtonIcon?: React.ReactNode
    onClickHandler?: MouseEventHandler
}

const IconButton: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { buttonType, textButtonType, textButtonLabel, iconButtonIcon, onClickHandler } = props

    const iconButton = (
        <div className="icon-button">
            <button className="button" onClick={onClickHandler} aria-label="Icon Button">
                {iconButtonIcon}
            </button>
            <div className="button-overlay"></div>
        </div>
    )

    const textButton = (
        <button className={`text-button ${textButtonType ? textButtonType : 'primary'}`} onClick={onClickHandler} aria-label="Text Button">
            {textButtonLabel}
        </button>
    )

    return match<ButtonTypeEnum, JSX.Element>(buttonType)
        .with(ButtonTypeEnum.ICON, () => iconButton)
        .with(ButtonTypeEnum.TEXT, () => textButton)
        .exhaustive()
}

export default IconButton
