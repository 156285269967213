import React, { ReactFragment } from 'react'
import { match } from 'ts-pattern'
import JobDetailsList from '../../components/JobDetailsList/JobDetailsList'

export enum JobUrl {
    CORE_PLATFORM_CPP = 'core-platform-cpp',
    CORE_PLATFORM_GO = 'core-platform-go',
    FE_DEVELOPER = 'fe-developer',
    SYS_ADMIN = 'sys-admin',
    TALENT_WANTED = 'talent-wanted'
}

export enum JobLocation {
    BELGRADE = 'Belgrade'
}

export enum EnvironmentType {
    ON_SITE = 'On-site'
}

export enum EmploymentType {
    FULL_TIME = 'Full-time'
}

export type JobUrlType = JobUrl | undefined

export const determineJobTitle = (jobUrl: JobUrlType): string =>
    match<JobUrlType, string>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => 'Core Platform C++')
        .with(JobUrl.CORE_PLATFORM_GO, () => 'Core Platform Go')
        .with(JobUrl.FE_DEVELOPER, () => 'Frontend Developer')
        .with(JobUrl.SYS_ADMIN, () => 'System Administrator')
        .otherwise(() => 'Missing an offer?')

const corePlatformCppFragment: ReactFragment = (
    <>
        Looking for candidates with a desire to work on building real-time low latency software. Any experience with networking, reliability
        protocols and generic programming is considered a plus.
    </>
)

const corePlatformCppDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Reliable low latency protocols</p>
                    <p>Platform agnostic data serialization</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>C++ specific</li>
                        <li>
                            <ul className="nested-list">
                                <li>ASIO (Networking TS)</li>
                                <li>Range-V3 (Ranges TS)</li>
                                <li>Concepts (Concepts TS)</li>
                                <li>&#123; fmt &#125; (Formatting TS)</li>
                                <li>LLFIO (low level file I/O library)</li>
                                <li>Clang Tidy</li>
                            </ul>
                        </li>
                        <li>TCP Networking</li>
                        <li>Linux operating system API</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <p>Unit testing and micro benchmarking</p>
                    <p>Continuous integration</p>
                    <p>Peer code review</p>
                    <p>Bazel build system</p>
                    <p>Dockerized services</p>
                </>
            }
        />
    </>
)

const corePlatformGoFragment: ReactFragment = (
    <>
        Looking for candidates with a desire to work on building highly-scalable web services. Any experience with real-time data
        distribution, user authentication and identity protocols and functional programming is considered a plus.
    </>
)

const corePlatformGoDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Reliable low latency protocols</p>
                    <p>Platform agnostic data serialization</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>Go specific</li>
                        <li>
                            <ul className="nested-list">
                                <li>GoRoutines and GoChannels</li>
                                <li>Gorilla libraries for creating web services</li>
                                <li>GolangCI-Lint</li>
                            </ul>
                        </li>
                        <li>HTTP and Websocket services</li>
                        <li>MongoDB</li>
                        <li>OAuth2 &#40;OIDC&#41;</li>
                        <li>Grafana and Prometheus for test data visualization</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <p>Unit testing and micro benchmarking</p>
                    <p>Continuous integration</p>
                    <p>Peer code review</p>
                    <p>Bazel build system</p>
                    <p>Dockerized services</p>
                </>
            }
        />
    </>
)

const feDevFragment: ReactFragment = (
    <>
        As a front end developer you will create fast,responsive UIs that process large amounts of real time data and present it to our
        customers and partners using the latest technologies.
    </>
)

const feDevDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'General'}
            listItems={
                <>
                    <p>Real time/performance oriented</p>
                    <p>Financial/trading interests/experience</p>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Technical'}
            listItems={
                <>
                    <ul>
                        <li>React.js</li>
                        <li>
                            <ul className="nested-list">
                                <li>React.Redux</li>
                                <li>Static type checking (TypeScript, Flow)</li>
                            </ul>
                        </li>
                        <li>React.Hooks experience is a plus</li>
                        <li>Experience with low latency APIs (Websockets, GraphQL)</li>
                        <li>Experience with canvas drawing and/or D3.js is a plus</li>
                        <li>Experience with React.Native is a plus</li>
                    </ul>
                </>
            }
        />
        <JobDetailsList
            listTitle={'Responsibilities'}
            listItems={
                <>
                    <ul>
                        <li>Creating React UI components</li>
                        <li>
                            <ul className="nested-list">
                                <li>Implementing component behavior</li>
                                <li>Writing component unit tests</li>
                            </ul>
                        </li>
                        <li>Using React UI components to create a single page app</li>
                        <li>
                            <ul className="nested-list">
                                <li>Responsive design using Flexbox</li>
                                <li>Writing automated tests</li>
                            </ul>
                        </li>
                        <li>Implement data visualization components (charts)</li>
                    </ul>
                </>
            }
        />
    </>
)

const sysAdminFragment: ReactFragment = (
    <>
        As a part of our team, you will be responsible for maintaining our production and test environments from infrastructure and
        networking perspective, maintaining office network and VPNs, optimizing kernels to support our High Frequency Trading platform,
        monitoring performance, etc.
        <br></br>
        <br></br>
        The candidate is expected to be comfortable with learning new tools and technologies. After the initial period of adaptation they
        will take on tasks that include working with Ansible, Docker, AWS and many more.
    </>
)

const sysAdminDescription: ReactFragment = (
    <>
        <JobDetailsList
            listTitle={'Required'}
            listItems={
                <>
                    <p>Strong experience in managing Linux distros (CentOS, Ubuntu)</p>
                    <p>Advanced networking knowledge</p>
                </>
            }
        />

        <JobDetailsList
            listTitle={'Desirable'}
            listItems={
                <>
                    <p>Experience with kernel optimization</p>
                    <p>Experience with Cisco and Mikrotik equipment</p>
                    <p>Experience with low latency networking equipment and libraries (&#60;name of NIC&#62;, OpenOnload, etc.)</p>
                    <p>Experience with provisioning tools (Puppet, Chef, Ansible)</p>
                    <p>Experience with Docker ecosystem</p>
                    <p>Experience with monitoring solutions (Prometheus, Grafana, etc.)</p>
                    <p>Basic knowledge of AWS</p>
                </>
            }
        />
    </>
)

const talentWantedFragment: ReactFragment = (
    <>
        These job listings are incomplete. If you don&#39;t see your specialty listed here, but you have years of professional experience,
        are really talented at what you do, and possess a set of skills which would help us create better products and experiences for our
        customers, we hope you&#39;ll apply below.
    </>
)

export const determineJobSummary = (jobUrl: JobUrlType): ReactFragment =>
    match<JobUrlType, ReactFragment>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => corePlatformCppFragment)
        .with(JobUrl.CORE_PLATFORM_GO, () => corePlatformGoFragment)
        .with(JobUrl.FE_DEVELOPER, () => feDevFragment)
        .with(JobUrl.SYS_ADMIN, () => sysAdminFragment)
        .otherwise(() => talentWantedFragment)

export const determineJobDescription = (jobUrl: JobUrlType): ReactFragment =>
    match<JobUrlType, ReactFragment>(jobUrl)
        .with(JobUrl.CORE_PLATFORM_CPP, () => corePlatformCppDescription)
        .with(JobUrl.CORE_PLATFORM_GO, () => corePlatformGoDescription)
        .with(JobUrl.FE_DEVELOPER, () => feDevDescription)
        .with(JobUrl.SYS_ADMIN, () => sysAdminDescription)
        .otherwise(() => <></>)
