import React from 'react'
import { ThemeType } from '../../globals/ThemeContext'
import Map from '../../components/Map/Map'
import ContactCard from '../../components/ContactCard/ContactCard'
import './ContactPage.scss'
import ThemeConsumer from '../ThemeConsumer'
import { locationUrl as locationUrlEnum, determineContactCardTitle, determineContactCardInfo } from './ContactPageData'

const ContactPage: React.FC = (): JSX.Element => {
    const location = locationUrlEnum.BELGRADE

    return (
        <ThemeConsumer theme={ThemeType.DARK}>
            <div className="contact-page" role="main">
                <div className="contact-info">
                    <ContactCard title={determineContactCardTitle(location)} info={determineContactCardInfo(location)} />
                </div>
                <Map location={location} />
            </div>
        </ThemeConsumer>
    )
}

export default ContactPage
