import { useMediaQuery } from 'react-responsive'

type ScreenSize = number

const smallScreenMax = 428 as ScreenSize
const mediumScreenMax = 960 as ScreenSize

const mediaQuery = (screenSize: ScreenSize, limit: 'lower' | 'upper') => `(${limit === 'upper' ? 'max' : 'min'}-width: ${screenSize}px)`

const useResponsive = (): { isSmall: boolean; isMedium: boolean } => {
    const isSmall = useMediaQuery({ query: mediaQuery(smallScreenMax, 'upper') })
    const isMedium = useMediaQuery({ query: mediaQuery(mediumScreenMax, 'upper') })

    return { isSmall, isMedium }
}

export default useResponsive
