import React from 'react'
import './Hero.scss'
import videoSrc from '../../../public/videos/hero-loop-video.mp4'
import HeroTextAnimation from '../HeroTextAnimation/HeroTextAnimation'

const Hero: React.FC = (): JSX.Element => {
    return (
        <div className="hero" role="main">
            <div className="container hero-container">
                <video className="bck-video" autoPlay playsInline loop muted>
                    <source src={videoSrc} type="video/mp4" />
                </video>

                <HeroTextAnimation
                    topElement={<h1 className="emp-text">Simplifying</h1>}
                    bottomElement={<h1 className="bottom-element">complexity.</h1>}
                />
            </div>
        </div>
    )
}

export default Hero
