import React from 'react'
import './HeroTextAnimation.scss'

interface IProps {
    topElement: string | React.ReactNode
    bottomElement: string | React.ReactNode
}

const HeroTextAnimation: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { topElement, bottomElement } = props

    return (
        <div className="text">
            <span className="top-element">{topElement}</span>
            <span className="slide">
                <span className="bottom-element">{bottomElement}</span>
            </span>
        </div>
    )
}

export default HeroTextAnimation
