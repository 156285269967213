import React from 'react'
import type { RouteComponentProps } from '@reach/router'
import { ThemeType } from '../../globals/ThemeContext'
import JobDetails from '../../components/JobDetails/JobDetails'
import './JobDetailsPage.scss'
import ThemeConsumer from '../ThemeConsumer'
import {
    JobUrlType,
    JobUrl as JobUrlEnum,
    JobLocation as JobLocationEnum,
    EnvironmentType as EnvironmentTypeEnum,
    EmploymentType as EmploymentTypeEnum,
    determineJobTitle,
    determineJobSummary,
    determineJobDescription
} from './JobDetailsData'
import { match, P } from 'ts-pattern'

interface UrlProps extends RouteComponentProps {
    jobUrl?: JobUrlEnum
}

const JobDetailsPage: React.FC<UrlProps> = (props: UrlProps): JSX.Element => {
    const { jobUrl } = props

    const exactPosition = (
        <div className="job-details-page" role="main">
            <JobDetails
                jobTitle={determineJobTitle(jobUrl)}
                location={JobLocationEnum.BELGRADE}
                environmentType={EnvironmentTypeEnum.ON_SITE}
                employmentType={EmploymentTypeEnum.FULL_TIME}
                summary={determineJobSummary(jobUrl)}
                description={determineJobDescription(jobUrl)}
            />
        </div>
    )

    const talentWanted = (
        <div className="job-details-page" role="main">
            <JobDetails
                jobTitle={determineJobTitle(JobUrlEnum.TALENT_WANTED)}
                location={JobLocationEnum.BELGRADE}
                environmentType={EnvironmentTypeEnum.ON_SITE}
                employmentType={EmploymentTypeEnum.FULL_TIME}
                summary={determineJobSummary(JobUrlEnum.TALENT_WANTED)}
                description={determineJobDescription(JobUrlEnum.TALENT_WANTED)}
            />
        </div>
    )

    return (
        <ThemeConsumer theme={ThemeType.MIDLIGHT}>
            {match<JobUrlType, JSX.Element>(jobUrl)
                .with(P.not(undefined), () => exactPosition)
                .otherwise(() => talentWanted)}
        </ThemeConsumer>
    )
}

export default JobDetailsPage
