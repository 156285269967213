import { createContext } from 'react'
import { match } from 'ts-pattern'

export enum ThemeType {
    LIGHT = 'light',
    DARK = 'dark',
    MIDLIGHT = 'mid-light'
}

export enum ThemeColor {
    LIGHT = '#edf1f3',
    DARK = '#0b0a10',
    MIDLIGHT = '#edf1f3',
    NONE = 'none'
}

/*eslint-disable*/
export const ThemeContext = createContext({
    theme: ThemeType.DARK,
    toggle: (theme: ThemeType) => {}
})
/*eslint-enable*/

const getColor = (type: ThemeType): ThemeColor =>
    match<ThemeType, ThemeColor>(type)
        .with(ThemeType.DARK, () => ThemeColor.DARK)
        .with(ThemeType.LIGHT, () => ThemeColor.LIGHT)
        .with(ThemeType.MIDLIGHT, () => ThemeColor.MIDLIGHT)
        .otherwise(() => ThemeColor.NONE)

export const setDocumentBackground = (type: ThemeType): void => {
    document.body.style.backgroundColor = getColor(type)
}
