import React, { ReactElement, useEffect } from 'react'
import { ThemeContext, ThemeType } from '../globals/ThemeContext'

interface IProps {
    children: ReactElement
    theme: ThemeType
}

let globalToggle: (tt: ThemeType) => void

const ThemeConsumer = (props: IProps): JSX.Element => {
    const { theme } = props

    useEffect(() => {
        globalToggle(theme)
    }, [theme])

    return (
        <ThemeContext.Consumer>
            {({ toggle }) => {
                globalToggle = toggle

                return props.children
            }}
        </ThemeContext.Consumer>
    )
}

export default ThemeConsumer
