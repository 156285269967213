import React from 'react'
import { ThemeType } from '../../globals/ThemeContext'
import Hero from '../../components/Hero/Hero'
import ThemeConsumer from '../ThemeConsumer'

const HomePage: React.FC = (): JSX.Element => (
    <ThemeConsumer theme={ThemeType.DARK}>
        <Hero />
    </ThemeConsumer>
)

export default HomePage
