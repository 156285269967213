import { Link } from '@reach/router'
import React from 'react'
import Button from '../Button/Button'
import ArrowIcon from '../../../public/assets/arrow-right.svg'

import { ButtonType as ButtonTypeEnum } from '../../globals/ButtonType'
import './JobItem.scss'

interface IProps {
    jobUrl: string
    jobTitle: string
}

const JobItem: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { jobUrl, jobTitle } = props

    return (
        <div className="job-item">
            <Link to={`/careers${jobUrl}`} className="links">
                <div className="job-title"> {jobTitle} </div>
                <Button buttonType={ButtonTypeEnum.ICON} iconButtonIcon={<ArrowIcon />} />
            </Link>
        </div>
    )
}

export default JobItem
