import React from 'react'
import './JobDetails.scss'
import Button from '../Button/Button'
import { ButtonType as ButtonTypeEnum, TextButtonType as TextButtonTypeEnum } from '../../globals/ButtonType'

interface IProps {
    jobTitle: string
    location: string
    environmentType: string
    employmentType: string
    summary: React.ReactNode
    description?: React.ReactNode
}

const JobDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { jobTitle, location, employmentType, environmentType, summary, description } = props

    const mailTo = () => {
        window.location.href = 'mailto:careers@inceptive.io'
    }

    return (
        <div className="container job-container">
            <h1 className="title">{jobTitle}</h1>
            <div className="tags">
                {location}
                <div className="divider-small"></div>
                {environmentType}
                <div className="divider-small"></div>
                {employmentType}
            </div>
            <div className="description">
                <p>{summary}</p>
                <div className="divider-big"></div>
            </div>
            {description}
            <Button
                buttonType={ButtonTypeEnum.TEXT}
                textButtonType={TextButtonTypeEnum.PRIMARY}
                textButtonLabel={'Apply here'}
                onClickHandler={mailTo}
            />
        </div>
    )
}

export default JobDetails
