import React from 'react'
import './NavbarMenu.scss'
import MenuItem from '../MenuItem/MenuItem'
import { locationUrl as locationUrlEnum } from '../../pages/ContactPage/ContactPageData'

interface IProps {
    isHamburgerActive: boolean
    onMenuItemClick: () => void
    theme: string
    locationUrl?: locationUrlEnum
}

const NavbarMenu: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { onMenuItemClick, isHamburgerActive, theme, locationUrl = locationUrlEnum.BELGRADE } = props

    return (
        <div className={`${isHamburgerActive ? 'nav-menu active' : 'nav-menu'} menu-theme ${theme}`}>
            <MenuItem onClick={onMenuItemClick} pageUrl={'/'} label={'Home'} />
            <MenuItem onClick={onMenuItemClick} pageUrl={'/about-us'} label="About us" />
            <MenuItem onClick={onMenuItemClick} pageUrl={'/careers'} label={'Careers'} />
            <MenuItem onClick={onMenuItemClick} pageUrl={`/contact/${locationUrl}`} label={'Contact'} />
        </div>
    )
}

export default NavbarMenu
