import React from 'react'
import './InteractiveCard.scss'

interface IProps {
    children: JSX.Element
}

const InteractiveCard = ({ children }: IProps): JSX.Element => (
    <div className="interactive-card">
        <div className="children-wrapper">{children}</div>
    </div>
)

export default InteractiveCard
