import React from 'react'
import { match } from 'ts-pattern'
import HamburgerClose from '../../../public/assets/hamburger-close.svg'
import HamburgerOpen from '../../../public/assets/hamburger-open.svg'
import './HamburgerIcon.scss'

interface IProps {
    isHamburgerActive: boolean
    onHamburgerClick: () => void
    theme: string
}

const HamburgerIcon: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { isHamburgerActive, onHamburgerClick, theme } = props

    const icon: JSX.Element = match<boolean, JSX.Element>(isHamburgerActive)
        .with(true, () => <HamburgerClose />)
        .otherwise(() => <HamburgerOpen />)

    return (
        <button className={`hamburger-icon ${theme}`} onClick={onHamburgerClick} aria-label="Hamburger">
            {icon}
        </button>
    )
}

export default HamburgerIcon
