import React from 'react'

interface IProps {
    link: string
    icon: React.ReactNode
    target: string
}

const IconLink: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { link, icon, target } = props

    return (
        <div className="icon-link">
            <a href={link} target={target}>
                {icon}
            </a>
        </div>
    )
}

export default IconLink
